// extracted by mini-css-extract-plugin
export var column = "ProviderBrochureEdit__column__oiqh0";
export var container = "ProviderBrochureEdit__container__Ne6l8";
export var flex = "ProviderBrochureEdit__flex__OcY6F";
export var flexColumn = "ProviderBrochureEdit__flexColumn__Rl_j5";
export var gap1 = "ProviderBrochureEdit__gap1__sqw0r";
export var gap2 = "ProviderBrochureEdit__gap2__niE_L";
export var gap3 = "ProviderBrochureEdit__gap3__Wbrug";
export var gap4 = "ProviderBrochureEdit__gap4__dxff5";
export var gap5 = "ProviderBrochureEdit__gap5___uRzx";
export var pageTitle = "ProviderBrochureEdit__pageTitle__p2L03";
export var row = "ProviderBrochureEdit__row__nRww9";