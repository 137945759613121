// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderBrochureEditForm from "./components/ProviderBrochureEditForm.res.js";
import * as ProvidersDashboardNavbar from "../../../providers/dashboard/index/components/navbar/ProvidersDashboardNavbar.res.js";
import * as ProviderBrochureEditScss from "./ProviderBrochureEdit.scss";

var css = ProviderBrochureEditScss;

function ProviderBrochureEdit$default(props) {
  var providerBrochure = props.providerBrochure;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ProvidersDashboardNavbar.make, {
                      selectedCategory: "EditBrochure",
                      id: Caml_option.some(providerBrochure.id),
                      slug: providerBrochure.slug
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.container,
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: css.pageTitle,
                              children: "Edit Provider Brochure"
                            }),
                        JsxRuntime.jsx(ProviderBrochureEditForm.make, {
                              providerBrochure: providerBrochure,
                              providers: props.providers
                            })
                      ]
                    })
              ]
            });
}

var $$default = ProviderBrochureEdit$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
